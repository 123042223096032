import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    lookupZip(ctx, zip) {
      return new Promise((resolve, reject) => {
        axios
          .post('/common/zip', { zip: zip })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveCfdi(ctx, cfdi ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/save', { cfdi: cfdi })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listCfdi(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCfdi(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/get', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCfdi(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/delete', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBundle(ctx, { uuid, type }) {
      return new Promise((resolve, reject) => {
        axios({
              method: 'post',
              url: '/cfdi/bundle',
              data: {
                 uuid: uuid,
                 type: type
              },
              responseType: 'arraybuffer'
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPreview(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios({
              method: 'post',
              url: '/cfdi/preview',
              data: {
                 uuid: uuid
              },
              responseType: 'arraybuffer'
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, { scope }) {
      return new Promise((resolve, reject) => {
        axios({
              method: 'post',
              url: '/cfdi/report',
              data: {
                 scope: scope
              },
              responseType: 'arraybuffer'
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contact/all', { params: { q: '' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/all', { params: { q: '' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCfdi(ctx, contact ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/add', { contact: contact })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCfdi(ctx, p) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/update', { uuid: p.uuid, cfdi: p.cfdi })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    emmitCfdi(ctx, p) {
      return new Promise((resolve, reject) => {
        console.log("cfdiStoreModule.emmitCfdi", p);
        axios
          .post('/cfdi/emmit', { cfdi : { uuid: p.uuid, ...(p.feature && { feature: { id: p.feature.id } } ) } })
          .then(response => resolve(response))
          .catch(error => { 
            if(error && error.response && error.response.status && error.response.status === 400) {
            let responseWrapped = {
                type: 'api.badrequest',
                error: {
                    message: error.response.data.message
                }
            }
            reject(responseWrapped)
        }})
      })
    },
    shareCfdi(ctx, share ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/share', { share })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    predownloadCfdi( ctx, { uuid } ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/predownload/pdf', { uuid : uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelEmmitedCfdi(ctx, { uuid, cancelData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/emmited/cancel', { uuid : uuid, cancelData: cancelData })
          .then(response => resolve(response))
          .catch(error => { 
            if(error && error.response && error.response.status && error.response.status === 400) {
              let responseWrapped = {
                  type: 'api.badrequest',
                  error: {
                      message: error.response.data.message
                  }
              }
              reject(responseWrapped)
            }
          })
      })
    },
    fetchEmmitedCfdi(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/emmited/fetch', { uuid : uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    emmitPaymentCfdi(ctx, { uuid, payment }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/emmited/payment', { uuid : uuid, payment: payment })
          .then(response => resolve(response))
          .catch(error => { 
            if(error && error.response && error.response.status && error.response.status === 400) {
            let responseWrapped = {
                type: 'api.badrequest',
                error: {
                    message: error.response.data.message
                }
            }
            reject(responseWrapped)
          }})
      })
    },
    emmitCreditCfdi(ctx, { uuid, credit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/emmited/credit', { uuid : uuid, credit: credit })
          .then(response => {
            console.log("response from store", response);
            resolve(response)})
          .catch(error => { 
            console.log("error from store", error);
            if(error && error.response && error.response.status && error.response.status === 400) {
            let responseWrapped = {
                type: 'api.badrequest',
                error: {
                    message: error.response.data.message
                }
            }
            reject(responseWrapped)
          }})
      })
    },
    getCEP(ctx, params ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/utility/banxico/cep/data', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelPaymentCfdi(ctx, { uuid, cancelData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/payment/cancel', { uuid, cancelData })
          .then(response => resolve(response))
          .catch(error => { 
            if(error && error.response && error.response.status && error.response.status === 400) {
            let responseWrapped = {
                type: 'api.badrequest',
                error: {
                    message: error.response.data.message
                }
            }
            reject(responseWrapped)
          }})
      })
    },
    cancelCreditNoteCfdi(ctx, { uuid, cancelData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cfdi/credit/cancel', { uuid, cancelData })
          .then(response => resolve(response))
          .catch(error => { 
            if(error && error.response && error.response.status && error.response.status === 400) {
            let responseWrapped = {
                type: 'api.badrequest',
                error: {
                    message: error.response.data.message
                }
            }
            reject(responseWrapped)
          }})
      })
    }
  },
}
