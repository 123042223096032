export default [
    {
        k: '01',
        v: 'Enero',
        per: ['01','02','03','04']
    }, {
        k: '02',
        v: 'Febrero',
        per: ['01','02','03','04']
    }, {
        k: '03',
        v: 'Marzo',
        per: ['01','02','03','04']
    }, {
        k: '04',
        v: 'Abril',
        per: ['01','02','03','04']
    }, {
        k: '05',
        v: 'Mayo',
        per: ['01','02','03','04']
    }, {
        k: '06',
        v: 'Junio',
        per: ['01','02','03','04']
    }, {
        k: '07',
        v: 'Julio',
        per: ['01','02','03','04']
    }, {
        k: '08',
        v: 'Agosto',
        per: ['01','02','03','04']
    }, {
        k: '09',
        v: 'Septiembre',
        per: ['01','02','03','04']
    }, {
        k: '10',
        v: 'Octubre',
        per: ['01','02','03','04']
    }, {
        k: '11',
        v: 'Noviembre',
        per: ['01','02','03','04']
    }, {
        k: '12',
        v: 'Diciembre',
        per: ['01','02','03','04']
    }, {
        k: '13',
        v: 'Ene-Feb',
        per: ['05']
    }, {
        k: '14',
        v: 'Mar-Abr',
        per: ['05']
    }, {
        k: '15',
        v: 'May-Jun',
        per: ['05']
    }, {
        k: '16',
        v: 'Jul-Ago',
        per: ['05']
    }, {
        k: '17',
        v: 'Sep-Oct',
        per: ['05']
    }, {
        k: '18',
        v: 'Nov-Dic',
        per: ['05']
    }
    ]