export default [
{
    k: 'G01',
    v: 'Adquisición de mercancías.',
    pt: ['pm', 'pf'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'G02',
    v: 'Devoluciones, descuentos o bonificaciones.',
    pt: ['pm', 'pf'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'G03',
    v: 'Gastos en general.',
    pt: ['pm', 'pf'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I01',
    v: 'Construcciones.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I02',
    v: 'Mobiliario y equipo de oficina por inversiones.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I03',
    v: 'Equipo de transporte.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I04',
    v: 'Equipo de computo y accesorios.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I05',
    v: 'Dados, troqueles, moldes, matrices y herramental.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I06',
    v: 'Comunicaciones telefónicas.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I07',
    v: 'Comunicaciones satelitales.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'I08',
    v: 'Otra maquinaria y equipo.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '606', '612', '620', '621', '622', '623', '624', '625', '626']
},
{
    k: 'D01',
    v: 'Honorarios médicos, dentales y gastos hospitalarios.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D02',
    v: 'Gastos médicos por incapacidad o discapacidad.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D03',
    v: 'Gastos funerales.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D04',
    v: 'Donativos.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D05',
    v: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D06',
    v: 'Aportaciones voluntarias al SAR.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D07',
    v: 'Primas por seguros de gastos médicos.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D08',
    v: 'Gastos de transportación escolar obligatoria.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D09',
    v: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'D10',
    v: 'Pagos por servicios educativos (colegiaturas).',
    pt: ['pf'],
    sr: ['605', '606', '608', '611', '612', '614', '607', '615', '625']
},
{
    k: 'S01',
    v: 'Sin efectos fiscales.',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '605', '606', '608', '610', '611', '612', '614', '616', '620', '621', '622', '623', '624', '607', '615', '625', '626']
},
{
    k: 'CP01',
    v: 'Pagos',
    pt: ['pf', 'pm'],
    sr: ['601', '603', '605', '606', '608', '610', '611', '612', '614', '616', '620', '621', '622', '623', '624', '607', '615', '625', '626']
},
{
    k: 'CN01',
    v: 'Nómina',
    pt: ['pf'],
    sr: ['605']
}
]