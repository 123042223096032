export default [
    {
        k: 'fg.tc.factura',
        v: 'Factura',
        e: 'true',
        ksat: 'I',
        vsat: 'Ingreso'
    },
    {
        k: 'fg.tc.pago',
        v: 'Complemento de Pago',
        e: 'true',
        ksat: 'P',
        vsat: 'Pago'
    },
    {
        k: 'fg.tc.ncredito',
        v: 'Nota de Crédito',
        e: 'false',
        ksat: 'E',
        vsat: 'Egreso'        
    }
]
