<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="10"
        md="10"
      >
        <b-form ref="formcfdiedit" @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <div style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">
              <div class="rw">
                <div class="c8">
                <!--<div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">-->

                <!-- Header: Left Content -->
                  <div class="rw">
                    <div class="c16">
                      <!--<logo />-->
                      <h4 class="text-primary invoice-logo">
                        {{ session ? session.business.name : '' }}
                      </h4>
                    </div>
                    <div class="c16">
                      <feather-icon 
                        id="business-phone"
                        icon="MapPinIcon"
                        class="cursor-pointer info"
                        size="10"
                      />
                        <span style="margin-left:10px; font-size: 9pt;">{{ invoiceBranch.address.street }}</span>
                    </div>
                    <div class="c16" style="margin-top: -4px;">
                      <span style="margin-left:21px; font-size: 9pt;">{{ invoiceBranch.address.suburb.v }}, {{ invoiceBranch.address.town.v }}, {{ invoiceBranch.address.state.v }}</span>
                    </div>
                    <div class="c16" style="margin-top: -4px;">
                      <div style="margin-left: -15px; cursor: pointer;">
                        <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret v-if="session && session.business.branches && session.business.branches.length > 0">
                          <template #button-content>
                            <feather-icon 
                              id="business-taxpayer-select"
                              icon="RefreshCwIcon"
                              class="cursor-pointer info"
                              size="10"
                              style="margin-top: -2px;"
                            />
                            <em style="padding-left: 10px;"><strong>{{ invoiceBranch.prefix }}</strong> - {{ invoiceBranch.address.zip }}</em>
                          </template>
                          <b-dropdown-item style="font-size: 9pt;" v-for="tx in ( session ? session.business.branches : [] )" :key="tx.id" href="#" @click="changeBusinessBranch(tx)">{{ tx.name }} - <strong>{{ tx.address.zip }}</strong></b-dropdown-item>
                        </b-dropdown>
                        <b-button size="sm" variant="link" toggle-class="text-decoration-none" no-caret style="font-size: 10pt;" v-else><strong>{{ invoiceBranch.prefix }}</strong> - {{ invoiceBranch.address.zip }}</b-button>
                      </div>
                    </div>
                    <div class="c16" style="margin-top: -5px;">
                      <feather-icon 
                        id="business-phone"
                        icon="PhoneIcon"
                        class="cursor-pointer info"
                        size="10"
                      />
                      <span style="margin-left:10px; font-size: 10pt;">{{ session ? session.business.phone : '' }}</span>
                    </div>
                    <div class="c16">
                      <div style="margin-left: -15px; margin-top: -5px; cursor: pointer;">
                        <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret v-if="session && Array.isArray(session.business.taxpayer) && session.business.taxpayer.length > 1">
                          <template #button-content>
                            <feather-icon 
                              id="business-taxpayer-select"
                              icon="RefreshCwIcon"
                              class="cursor-pointer info"
                              size="12"
                            />
                            <em style="padding-left: 10px;">{{ invoiceData.businessTaxpayer.v }}</em>
                          </template>
                          <b-dropdown-item style="font-size: 9pt;" v-for="tx in ( session ? session.business.taxpayer : [] )" :key="tx.k" href="#" @click="changeBusinessRegime(tx)">{{ tx.v }}</b-dropdown-item>
                        </b-dropdown>
                        <b-button size="sm" variant="link" toggle-class="text-decoration-none" no-caret style="font-size: 10pt;" v-else>{{ invoiceData.businessTaxpayer.v }}</b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c8">
                <!-- Header: Right Content -->
                  <div class="rw">
                    <div class="c4">
                      <h6 style="font-size: 1.0rem; letter-spacing: -0.54px; padding-top: 8%; padding-bottom: 8%;">
                        {{ t('Invoice To') }}
                      </h6>
                    </div>
                    <div class="c12">
                      <v-select
                        v-model="invoiceData.customer"
                        :options="contacts"
                        label="name"
                        input-id="invoice-data-client"
                        :clearable="false"
                        @input="changeCustomer"
                      >
                        <template #selected-option="option">
                          <div v-if="option.name !== ''" style="display: flex; align-items: baseline">
                            <span style="font-weight: 500;">{{ option.name }}</span> <span v-if="option.alias !== ''" style="padding-left: 5px; font-size: 9pt;"> ({{ option.alias }}) </span>
                          </div>
                          <div v-else>
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.name }} <span v-if="option.alias !== ''" style="font-size: 9pt;"> ({{ option.alias }})</span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="rw" style="margin-top: -10px; margin-left: 18px;">
                    <div class="c4">
                    </div>
                    <div class="c12">
                      <h5 v-if="invoiceData.customer"> {{ invoiceData.customer.taxid }}  <span style="font-size: 9pt;">({{ invoiceData.customer.email }})</span> </h5>
                    </div>
                  </div>
                  <!-- Select Regime -->
                  <div class="rw" style="margin-top: -10px; margin-left: 18px;">
                    <div class="c4"></div>
                    <div class="c12" >
                      <div style="margin-left: -15px; margin-top: -5px; cursor: pointer;" v-if="invoiceData.customer">
                        <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" dropleft no-caret v-if="!isGlobalInvoice">
                          <template #button-content>
                            <feather-icon 
                              id="business-taxpayer-select"
                              icon="RefreshCwIcon"
                              class="cursor-pointer info"
                              size="12"
                            />
                            <em style="padding-left: 10px;">{{ invoiceData.customerTaxpayer.v.length > 57 ? invoiceData.customerTaxpayer.v.substr(0,57) + '...' : invoiceData.customerTaxpayer.v }}</em>
                          </template>
                          <b-dropdown-item style="font-size: 8pt;" v-for="tx in taxpayerList.filter(c => c.pt.find(o => o === invoiceData.customer.type))" :key="tx.k" href="#" @click="changeCustomerRegime(tx)">{{ tx.v }}</b-dropdown-item>
                        </b-dropdown>
                        <b-button size="sm" variant="link" toggle-class="text-decoration-none" no-caret style="font-size: 10pt; font-style: italic;" v-else>{{ invoiceData.customerTaxpayer.v }}</b-button>
                      </div>

                    </div>
                  </div>
                  <!-- Select Invoice Use -->
                  <div class="rw" style="margin-top: -10px; margin-left: 18px;">
                    <div class="c4"></div>
                    <div class="c12" >
                      <div style="margin-left: -15px; margin-top: -5px; cursor: pointer;" v-if="invoiceData.customer">
                        <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" dropleft no-caret v-if="!isGlobalInvoice">
                          <template #button-content>
                            <feather-icon 
                              id="business-taxpayer-select"
                              icon="TrelloIcon"
                              class="cursor-pointer info"
                              size="12"
                            />
                            <em style="padding-left: 10px;">{{ invoiceData.customerInvoiceuse.v.length > 57 ? invoiceData.customerInvoiceuse.v.substr(0,57) + '...' : invoiceData.customerInvoiceuse.v }}</em>
                          </template>
                          <b-dropdown-item style="font-size: 8pt; height: 24px;" v-for="tx in invoiceuseList.filter(c => (c.pt.find(o => o === invoiceData.customer.type) && c.sr.find(u => u === invoiceData.customerTaxpayer.k)))" :key="tx.k" href="#" @click="invoiceData.customerInvoiceuse = tx; cfdiSaved = false">{{ tx.v }}</b-dropdown-item>
                        </b-dropdown>
                        <b-button size="sm" variant="link" toggle-class="text-decoration-none" no-caret style="font-size: 10pt; font-style: italic;" v-else>{{ invoiceData.customerInvoiceuse.v }}</b-button>
                      </div>

                    </div>
                  </div>




                </div>
              </div>
            </div>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <div class="rw" v-if="isGlobalInvoice" style="padding-bottom: 0.5rem; padding-left: 2rem; padding-right: 2rem;">
              <div class="c12">
                <div class="rw" style="padding-bottom: 0.5rem;">
                  <div class="c2" style="font-size: 1.0rem; letter-spacing: -0.54px; padding-top: 8px; padding-bottom: 8px;">
                    {{ t('Periodicity') }}
                  </div>
                  <div class="c3">
                    <v-select
                      v-model="invoiceData.global.periodicity"
                      :options="periodicityList"
                      label="v"
                      input-id="invoice-data-periodicity"
                      @input="cfdiSaved = false"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="c2" style="font-size: 1.0rem; letter-spacing: -0.54px; padding-top: 8px; padding-bottom: 8px;">
                    {{ t('Period') }}
                  </div>
                  <div class="c3">
                    <v-select
                      v-model="invoiceData.global.period"
                      :options="periodList.filter(c => c.per.find(o => o === (invoiceData.global.periodicity !== null ? invoiceData.global.periodicity.k : '')))"
                      label="v"
                      input-id="invoice-data-period"
                      @input="cfdiSaved = false"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="c1" style="font-size: 1.0rem; letter-spacing: -0.54px; padding-top: 8px; padding-bottom: 8px;">
                    {{ t('Year') }}
                  </div>
                  <div class="c3">
                    <v-select
                      v-model="invoiceData.global.year"
                      :options="years"
                      label="v"
                      input-id="invoice-data-year"
                      @input="cfdiSaved = false"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>

            <hr class="invoice-spacing" v-if="isGlobalInvoice">

            <!-- Items Section -->
            <div class="rw menu_sticky" style="padding-left: 2.5rem; padding-right: 2.5rem; padding-top: 1.0rem;" v-if="invoiceData.customer">
              <div class="c12">
                <v-select
                  v-model="currentProduct"
                  label="name"
                  :options="products.filter(o => o.config === '*' || currentConfig[o.config] === true)"
                  :getOptionKey="getOptionKeyHelper"
                  :filter="filterHelper"
                  :clearable="false"
                  placeholder="Select Item"
                  style="padding-bottom: 1rem"
                >
                  <template v-slot:option="option">
                      {{ option.id }} : {{ option.name }}
                  </template>
                </v-select>
              </div>
              <div class="c1">
              </div>
              <div class="c3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  style="width: 100%"
                  @click="addNewItemInItemForm"
                >
                  {{ t('Add Product')}}
                </b-button>
              </div>
            </div>

            <div class="rw" style="padding-left: 2.5rem; padding-right: 2.5rem;">
              <div class="c16" style="padding-top: 0.5rem;" v-for="(item, index) in invoiceData.items" :key="index" ref="row">
                <div style="position: absolute; right: 3.75rem; margin-top: 0.5rem;">
                  <feather-icon
                    :id="`cfdi-item-${index}`"
                    class="cursor-pointer text-danger"
                    icon="Trash2Icon"
                    size="16"
                    @click="removeItem(index)"
                  />
                </div>
                <div class="rw" style="padding: 0.25rem; border: 1px solid #cdcdcd; border-radius: 4px;">
                  <div class="c2">
                    <div class="rw">
                      <b-form-group
                        :label="t('Id')"
                        :label-for="`item-id-${index}`"
                        style="margin-bottom: 0.25rem; "
                      >
                          <b-form-input
                            :id="`item-id-${index}`"
                            disabled
                            v-model="item.id"
                            size="sm"
                          />
                      </b-form-group>
                    </div>
                    <div class="rw">
                      <b-form-group
                        :label="`${t('Sat Class')} / ${t('Sat Unit')}`"
                        :label-for="`item-satid-${index}`"
                        style="margin-bottom: 0.25rem; "
                      >
                          <b-form-input 
                            :id="`item-satid-${index}`"
                            disabled
                            :value="`${item.satid} / ${item.satu}`"
                            size="sm"
                          />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="c12">
                    <div class="rw" style="margin-left: -0.5rem; margin-right: -0.5rem;">
                      <div class="c2">
                        <b-form-group
                          :label="t('Qty')"
                          :label-for="`item-qty-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-qty-${index}`"
                            v-model="item.qty"
                            size="sm"
                            type="number"
                            style="text-align: right;"
                            @input="calculate(index)"
                          />
                        </b-form-group>
                      </div>
                      <div :class="[item.price_t.ieps ? 'c8' : 'c10']">
                        <b-form-group
                          :label="t('Desc')"
                          :label-for="`item-desc-${index}`"
                          style="margin-bottom: 0.25rem; "
                        >
                          <b-form-input
                            :id="`item-desc-${index}`"
                            v-model="item.name"
                            size="sm"
                            class="mb-2"
                          />
                        </b-form-group>
                      </div>
                      <div class="c2">
                        <b-form-group
                          :label="t('Amount')"
                          :label-for="`item-price-${index}`"
                          style="margin-bottom: 0.25rem; "
                        >
                            <b-form-input
                              :id="`item-price-${index}`"
                              :disabled="item.fixed"
                              v-model="item.price"
                              size="sm"
                              type="number"
                              style="text-align: right;"
                              @input="calculate(index)"
                            />
                        </b-form-group>
                      </div>
                      <div class="c2" v-if="item.price_t.ieps">
                        <b-form-group
                          :label="`${t('IEPS')} (${item.taxes.ieps_t.t === 'rate' ? item.taxes.ieps_t.r + '%' : 'Cuota'})`"
                          :label-for="`item-ieps-${index}`"
                        >
                          <b-form-input
                            :id="`item-ieps-${index}`"
                            disabled
                            size="sm"
                            :value="item.price_t.ieps.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                            style="text-align: right;"
                          />
                        </b-form-group>
                      </div>
                      <div class="c2">
                        <b-form-group
                          :label="t('Discount')"
                          :label-for="`item-discount-${index}`"
                          style="margin-bottom: 0.25rem; "
                        >
                          <b-form-input
                            :id="`item-discount-${index}`"
                            size="sm"
                            v-model="item.discount.value"
                            style="text-align: right;"
                            @input="calculate(index)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                    <div class="rw" style="margin-top: -1.25rem; margin-left: -0.5rem;" v-if="item.config === 'fg.leasing.realstate'">
                      <div class="c4">
                        <b-form-group
                          :label="t('Cadastral Key')"
                          :label-for="`item-leasing-cadastral-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-leasing-cadastral-${index}`"
                            disabled
                            v-model="item.extra.lease.id"
                            size="sm"
                            style="text-align: right;"
                          />
                        </b-form-group>
                      </div>
                      <div class="c12">
                      </div>
                    </div>
                    <div class="rw" style="margin-top: -1.25rem; margin-left: -0.5rem;" v-if="item.config === 'fg.school.private'">
                      <div class="c3">
                        <b-form-group
                          :label="t('RVOE / CCT')"
                          :label-for="`item-school-rvoe-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-school-rvoe-${index}`"
                            disabled
                            v-model="item.extra.school.auth"
                            size="sm"
                            type="text"
                          />
                        </b-form-group>
                      </div>
                      <div class="c3">
                        <b-form-group
                          :label="t('School Level')"
                          :label-for="`item-school-level-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-school-level-${index}`"
                            disabled
                            v-model="item.extra.school.level.v"
                            size="sm"
                            type="text"
                          />
                        </b-form-group>
                      </div>
                      <div class="c6">
                        <b-form-group
                          :label="t('Name')"
                          :label-for="`item-school-name-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-school-name-${index}`"
                            v-model="item.extra.school._alummni_name"
                            size="sm"
                            type="text"
                            autocomplete="off"
                            v-uppercase
                          />
                        </b-form-group>
                      </div>
                      <div class="c4">
                        <b-form-group
                          :label="t('CURP')"
                          :label-for="`item-school-curp-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <b-form-input
                            :id="`item-school-curp-${index}`"
                            v-model="item.extra.school._alummni_popid"
                            size="sm"
                            type="text"
                            autocomplete="off"
                            :formatter="fmtCURP"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </div>  
                  <div class="c2">
                    <div class="rw">
                      <b-form-group
                        :label="t('Subtotal')"
                        :label-for="`item-subtotal-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-subtotal-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                    </div>
                    <div class="rw" v-if="item.taxes.ieps_t">
                      <b-form-group
                        :label="`${t('IEPS Traslate')} (${item.taxes.ieps_t.t === 'rate' ? item.taxes.ieps_t.r + '%' : 'Cuota'})`"
                        :label-for="`item-ieps-traslate-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-ieps-traslate-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.taxes.ieps_t.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                          />
                      </b-form-group>
                    </div>
                    <div class="rw" v-if="item.taxes.iva_t">
                      <b-form-group
                        :label="item.taxes.iva_t.r === 'Exento' ? t('IVA Exempt') : `${t('IVA Traslate')} (${item.taxes.iva_t.r}%)` "
                        :label-for="`item-iva-traslate-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-iva-traslate-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.taxes.iva_t.r === 'Exento' ? t('Exempt') : item.taxes.iva_t.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                    </div>
                    
                    <div class="rw" v-if="item.taxes.iva_r">
                      <b-form-group
                        :label="`${t('IVA Retain')} (${item.taxes.iva_r.r}%)` "
                        :label-for="`item-iva-retain-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-iva-retain-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.taxes.iva_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                    </div> 
                    <div class="rw" v-if="item.taxes.isr_r">
                      <b-form-group
                        :label="`${t('ISR Retention')} (${item.taxes.isr_r.r}%)` "
                        :label-for="`item-isr-retain-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-isr-retain-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.taxes.isr_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                    </div> 
                    <div class="rw" v-if="item.taxes.ieps_r">
                      <b-form-group
                        :label="`${t('IEPS Retain')} (${item.taxes.ieps_r.t === 'rate' ? item.taxes.ieps_r.r + '%' : 'Cuota'})` "
                        :label-for="`item-ieps-retain-${index}`"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          :id="`item-ieps-retain-${index}`"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="item.taxes.ieps_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                    </div> 
                  </div>
                </div>
              </div>
            </div>

            <!-- Invoice Description: Total -->
            
            <div class="rw" style="padding-left: 2.5rem; padding-right: 2.5rem;">
              <div class="c16">
                <div class="rw" style="padding: 0.25rem; border: 1px solid #cdcdcd; border-radius: 4px;">
                  <div class="c2" style="margin-left: auto;">
                    <b-form-group
                        :label="t('Subtotal')"
                        label-for="invoice-subtotal"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-subtotal"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.summary.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2" v-if="invoiceData.summary.discount > 0.0">
                    <b-form-group
                        :label="t('Discount')"
                        label-for="invoice-discount"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-discount"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.summary.discount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2" v-if="invoiceData.taxes.total_iva_t > 0.0">
                    <b-form-group
                        :label="t('IVA Traslate')"
                        label-for="invoice-total-iva-t"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-total-iva-t"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.taxes.total_iva_t.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2" v-if="invoiceData.taxes.total_ieps_t > 0.0">
                    <b-form-group
                        :label="t('IEPS Traslate')"
                        label-for="invoice-total-ieps-t"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-total-ieps-t"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.taxes.total_ieps_t.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2" v-if="invoiceData.taxes.total_iva_r > 0.0">
                    <b-form-group
                        :label="t('IVA Retain')"
                        label-for="invoice-total-iva-r"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-total-iva-r"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.taxes.total_iva_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2" v-if="invoiceData.taxes.total_isr_r > 0.0">
                    <b-form-group
                        :label="t('ISR Retention')"
                        label-for="invoice-total-isr-r"
                        style="margin-bottom: 0.25rem; width: 100%;"
                      >
                        <b-form-input
                          id="invoice-total-isr-r"
                          disabled
                          size="sm"
                          style="text-align: right;"
                          :value="invoiceData.taxes.total_isr_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                  <div class="c2">
                    <b-form-group
                        :label="currentCFDiCurrency !== 'MXN' &&  currentCFDiCurrency !== '' ? `${t('Total')} (${currentCFDiCurrency})` : t('Total')"
                        label-for="invoice-total"
                        style="margin-bottom: 0.25rem; width: 100%; font-weight: 700; font-size: 12pt;"
                      >
                        <b-form-input
                          id="invoice-total"
                          disabled
                          size="sm"
                          style="text-align: right; font-weight: 700; font-size: 11pt;"
                          :value="invoiceData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                        />
                      </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!-- Note -->
            <div class="rw" style="padding-left: 3.0rem; padding-right: 3.0rem; padding-bottom: 2.0rem;">
              <b-form-group
                :label="t('Observations')"
                label-for="invoice-observations"
                style="margin-bottom: 0.25rem; width: 100%;"
              >
                <b-form-textarea v-model="invoiceData.observations" 
                rows="3"
                no-resize 
              />            
              </b-form-group>        
            </div>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="2"
        xl="2"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-form-group          
          v-if="invoiceData.operation"
          label-for="invoice-folio"
        >
          <div style="width: 100%; padding-bottom: 10px;">
            <p class="card-text mb-25" style="font-size: 11pt; font-weight: bold; text-align: center; text-overflow: ellipsis; width: 100%;">
              {{ t(invoiceData.operation.id) }}
            </p>
          </div>
          <template v-if="invoiceData.operation.type && invoiceData.operation.type === 'multiple'">
            <div class="d-flex justify-content-between flex-column">
              <b-card v-for="(c, index) in invoiceData.operation.list" :key="index" body-class="cfdi-add-card-body" style="margin-bottom: 0.5rem;">
                <div class="d-flex justify-content-between flex-md-row flex-column">
                  <div style="width: 35%">
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Invoice Type')}}:
                    </p>
                    <p class="card-text mb-25 text-danger" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.index.serie }} {{ c.cfdi.index.folio }}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Fecha de Emision') }}:
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Subtotal') }}:
                    </p>                      
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Monto Original') }}:
                    </p>                
                  </div>
                  <!-- Header: Right Content -->
                  <div style="width: 65%">
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.type.v }} ({{ c.cfdi.type.vsat }})</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.index.folio_tax }}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.index.edate }}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.summary.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                    </p>                    
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ c.cfdi.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                    </p>                  
                  </div>
                </div>
              </b-card>
              <b-card body-class="cfdi-add-card-body" style="margin-bottom: -0.25rem;">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing" style="margin-bottom: 0.0rem; margin-top: 0.0rem;">
                  <div style="width: 35%">
                    <p style="margin-bottom: 0.25rem;">{{ t('SUM SubTotal') }}:</p>
                    <p style="margin-bottom: 0.25rem;">{{ t('Monto Total') }}:</p>
                  </div>
                  <div style="width: 65%">
                    <p style="margin-bottom: 0.25rem;"><span style="font-size: 10.5pt; font-weight: 600; width: 100%;" class="invoice-number">{{ invoiceReplacedMultipleDataSubTAmount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span></p>
                    <p style="margin-bottom: 0.25rem;"><span style="font-size: 10.5pt; font-weight: 600; width: 100%;" class="invoice-number">{{ invoiceReplacedMultipleDataAmount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span></p>
                  </div>
                </div>
              </b-card>
            </div>
          </template>
          <template v-else>
            <!-- Header: Left Content -->
            <div class="d-flex justify-content-between flex-column">
              <b-card body-class="cfdi-add-card-body" style="margin-bottom: -0.25rem;">
                <div class="d-flex justify-content-between flex-md-row flex-column" style="margin-bottom: 0.0rem; margin-top: 0.0rem;">
                  <div style="width: 35%">
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Invoice Type')}}:
                    </p>
                    <p class="card-text mb-25 text-danger" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.index.serie : '' }} {{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.index.folio : '' }}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Fecha de Emision') }}:
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; text-overflow: ellipsis; width: 100%;">
                      {{ t('Monto Original') }}:
                    </p>                
                    
                  </div>
                  <!-- Header: Right Content -->
                  <div style="width: 65%">
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.type.v : ''}} ({{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.type.vsat : ''}})</span>
                    </p>   
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.index.folio_tax : ''}}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.index.edate : ''}}</span>
                    </p>
                    <p class="card-text mb-25" style="font-size: 9pt; font-weight: 600; width: 100%;">
                      <span class="invoice-number">{{ invoiceData && invoiceData.operation ? invoiceData.operation.cfdi.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
                    </p>                  
                  </div>
                </div>
              </b-card>
            </div>
          </template>
        </b-form-group>

        <!-- Action Buttons -->
        <b-card style="margin-top: -0.25rem;">
          <div class="rw" style="margin-top: -1.5rem; margin-left: -1.0rem; margin-right: -1.0rem; margin-bottom: -1.0rem;">
            <div class="c16">
              <div class="rw">
                <b-form-group
                  :label="t('Payment Method')"
                  label-for="payment-method"
                  style="width: 100%"
                >
                  <v-select
                    v-model="invoiceData.payment.method"
                    :options="paymentMethodList"
                    :disabled="isGlobalInvoice"
                    input-id="payment-method"
                    text-field="v"
                    label="v"
                    @input="changePaymentMethod"
                    :clearable="false"
                  >
                    <template #selected-option="option">
                      <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                        <strong>{{ option.k }}</strong>
                        <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
                      </div>
                      <div v-else>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      {{ option.k }} : {{ option.v }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="rw" style="margin-top: -0.25rem;">
                <b-form-group
                  :label="t('Payment Form')"
                  label-for="payment-form"
                  style="width: 100%"
                >
                  <v-select
                    v-model="invoiceData.payment.form"
                    :options="paymentFormListOrdered"
                    :disabled="invoiceData.payment.method.k === 'PPD'"
                    input-id="payment-form"
                    text-field="v"
                    label="v"
                    @input="cfdiSaved = false"
                    :clearable="false"
                  >
                    <template #selected-option="option">
                      <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                        <strong>{{ option.k }}</strong>
                        <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
                      </div>
                      <div v-else>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      {{ option.k }} : {{ option.v }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="rw" v-if="currentCFDiCurrency !== 'MXN' &&  currentCFDiCurrency !== ''">
                <b-form-group
                  :label="`${t('Currency Exchange')} (MXN/${currentCFDiCurrency})`"
                  label-for="payment-currency-exchange"
                  style="width: 100%"
                >
                  <b-form-input
                    id="payment-currency-exchange"
                    v-model="invoiceData.payment.currencyMXNExchange"
                    size="sm"
                    type="number"
                  />
                </b-form-group>
              </div>
              <div class="rw">
                <div class="c16" style="padding-left: 0.0rem; padding-right: 0.0rem; padding-bottom: 0.5rem;">
                  <b-overlay
                    :show="onSaveBusy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    style="width: 100%;"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      style="width: 100%;"
                      block
                      @click="onSave"
                    >
                      <feather-icon
                        size="14"
                        icon="UploadCloudIcon"
                      />
                      &nbsp;&nbsp;
                      <span style="font-weight: 500;">{{ t('Save_Cfdi') }}</span>
                    </b-button>
                  </b-overlay>
                </div>
                <div class="c16" style="padding-left: 0.0rem; padding-right: 0.0rem; padding-bottom: 0.5rem;" v-if="isEmmitReady">
                  <b-overlay
                    :show="onEmmitBusy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    style="width: 100%;"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="secondary"
                      style="width: 100%;"
                      block
                      @click="onEmmit"
                    >
                      <feather-icon
                        size="14"
                        icon="SendIcon"
                      />
                      &nbsp;&nbsp;
                      <span style="font-weight: 500;">{{ t('Emmit_Cfdi') }}</span>
                    </b-button>
                  </b-overlay>
                </div>
                <div class="c16" style="padding-left: 0.0rem; padding-right: 0.0rem;" v-if="isFeatureCollectionCapable && isEmmitReady">
                  <b-overlay
                    :show="onEmmitBusy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    style="width: 100%;"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="secondary"
                      style="width: 100%;"
                      block
                      @click="onEmmit( { id: 'fgFeatureCollections' })"
                    >
                      <feather-icon
                        size="14"
                        icon="SendIcon"
                      />
                      &nbsp;&nbsp;
                      <span style="font-weight: 500;">{{ t('EmmitCollect_Cfdi') }}</span>
                    </b-button>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div>

    </div>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BOverlay, BButton, BCardText, BForm, BDropdown, BDropdownItem, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BFormSelect, BFormSelectOption, BPopover, VBToggle,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import cfdiStoreModule from './cfdiStoreModule'
import CfdiSidebarAddNewContact from './CfdiSidebarAddNewContact.vue'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import invoiceTypeList from '@/@catalogs/sat/c_tipocomprobante'
import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
import paymentFormList from '@/@catalogs/sat/c_formapago'
import paymentMethodList from '@/@catalogs/sat/c_metodopago'
import periodicityList from '@/@catalogs/sat/c_periodicidad'
import periodList from '@/@catalogs/sat/c_meses'
/** fer@arkeline.dev 22/03/2023 Product Currency Feature on CFDi Add */
import currencyList from '@/@catalogs/sat/c_moneda'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BOverlay,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BDropdown, 
    BDropdownItem,
    BFormSelect,
    BFormSelectOption,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    CfdiSidebarAddNewContact,
  },
  data() {
    return {
      taxpayerList,
      invoiceTypeList,
      invoiceuseList,
      paymentFormList,
      paymentFormListOrdered: paymentFormList.sort((a,b) => a.or - b.or),
      paymentMethodList,
      periodicityList,
      periodList,
      /** fer@arkeline.dev 22/03/2023 Product Currency Feature for CFDi Add */
      currencyList,
      currentProduct: {},
      currentConfig: {},
      businessTaxpayer: {k:'',v:''},
      cfdiSaved: false,
      onSaveBusy: false,
      onEmmitBusy: false,

      /** fer@arkeline.dev 15/07/2023 We we use multiple cfdi as discount, we need to accumulate on these fields */
      invoiceReplacedMultipleData: [],
      invoiceReplacedMultipleDataAmount: 0.0,
      /** ILL 04/07/2027 Variable para la sumatoria de subtotales para facturas que seran usasdas como anticipo */
      invoiceReplacedMultipleDataSubTAmount: 0.0,
      currentCFDiCurrency: '',
      /** fer@arkeline.dev 25/05/2024 Feature Behaviour */
      session: null,
      /** fer@arkeline.io
       *  Collection Feature
       */
      isFeatureCollectionCapable: false,
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {

    /** fer@arkeline.io
     *  Check if the feature is enabled
     */
    this.session = JSON.parse(localStorage.getItem('session'))
    this.isFeatureCollectionCapable = this.session.businessFeatures.findIndex(a => a.package === 'fgFeatureCollections') !== -1 ? true : false

    this.$store.dispatch('fg-cfdi/fetchCfdi',{ uuid: this.$route.params.uuid })
      .then(response => { 
        const o = response.data.cfdi 
        const ro = {
          uuid: o.uuid || '',
          id: o.id || '',
          type: o.type || null,
          business: o.business || null,
          businessTaxpayer: this.evalValidTaxRegime(o),
          customer: o.customer || null,
          customerTaxpayer: o.customerTaxpayer ? o.customerTaxpayer : (o.customer ? o.customer.taxertype : null),
          customerInvoiceuse: o.customerInvoiceuse ? o.customerInvoiceuse : (o.customer ? o.customer.invoiceuse : null),
          global: {
            period: o.global && o.global.period || null,
            periodicity: o.global && o.global.periodicity || null,
            year: o.global && o.global.year || null,
          },
          items: o.items || [],
          summary: o.summary || {
            subtotal: 0.0,
            total_t: 0.0,
            total_r: 0.0,
            total: 0.0
          },
          taxes: o.taxes || {
            total_iva_t: 0.0,
            total_ieps_t: 0.0,
            total_iva_r: 0.0,
            total_isr_r: 0.0
          },
          addendum: o.addendum || {},
          observations: o.observations || '',
          payment: {
            method: o.payment.method || {},
            form: o.payment.form || {},
            currency: o.payment.currency || { k: 'MXN', v: 'Peso Mexicano', or: 1, de: 2 },
            currencyMXNExchange: o.payment.currencyMXNExchange || 1.00 
          }
        }

        if(o.operation) {
          ro['operation'] = o.operation

          if(o.operation.type && o.operation.type === 'multiple') {
            for( let cfdiItem of o.operation.list) {
              //console.log('cfdiItem',cfdiItem)
              /** We need to check if its possible to perform calculations with 2 decimals only instead o f 6 */
              this.invoiceReplacedMultipleDataAmount = parseFloat(parseFloat(this.invoiceReplacedMultipleDataAmount + parseFloat(cfdiItem.cfdi.summary.total.toFixed(2))).toFixed(2))
              /** ILL 04/07/2027 Variable para la sumatoria de subtotales para facturas que seran usasdas como anticipo */
              this.invoiceReplacedMultipleDataSubTAmount = parseFloat(parseFloat(this.invoiceReplacedMultipleDataSubTAmount + parseFloat(cfdiItem.cfdi.summary.subtotal.toFixed(2))).toFixed(2))
            }
          }
        }

        if(o.business.branch) {
          this.invoiceBranch = o.business.branch
        } else {
          this.invoiceBranch.address = o.business.address.main
        }
        

        this.invoiceData = ro
        this.invoiceUUID = response.data.cfdi.uuid 
        this.cfdiSaved = true
        this.currentCFDiCurrency = o.payment.currency.k

        this.changeBusinessRegime(ro.businessTaxpayer)
        this.changeCustomerInvoiceUse(ro.customerInvoiceuse)
        })

    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    isGlobalInvoice: {
      get () {
        //return this.invoiceData.customer && (this.invoiceData.customer.taxid === 'XEXX010101000' || this.invoiceData.customer.taxid === 'XAXX010101000')
        return this.invoiceData.customer && ['XAXX010101000','XEXX010101000'].find(a => a === this.invoiceData.customer.taxid) && this.invoiceData.customer.name === 'PUBLICO EN GENERAL'
      }
    },
    isCustomerGeneral: {
      get () {
        return this.invoiceData.customer && ['XAXX010101000','XEXX010101000'].find(a => a === this.invoiceData.customer.taxid)
      }
    },
    isEmmitReady: {
      get () {
        //let a = (Array.isArray(this.session.business.taxpayer) ? this.session.business.taxpayer : [this.session.business.taxpayer])
        let b = this.invoiceData && this.invoiceData.operation && ( this.invoiceData.operation.id === 'cfdi.operation.new-withdiscount' || this.invoiceData.operation.id === 'cfdi.operation.new-withdiscount-ol' ) ? ( this.invoiceData.summary.discount >= this.invoiceReplacedMultipleDataSubTAmount ) : true
        let c = this.invoiceData && this.invoiceData.summary.total && this.invoiceData.summary.total > 0.0 ? true : false
        console.log('isEmmitReady => b ', b	)
        
        return this.cfdiSaved 
              //&& a.find(o => o.k === this.invoiceData.businessTaxpayer.k) !== undefined
              && this.invoiceUUID !== '' 
               && this.invoiceData.customer 
               && this.invoiceData.items.length !== 0
               && this.invoiceData.payment.method.hasOwnProperty('k')
               && this.invoiceData.payment.method.k !== '' 
               && this.invoiceData.payment.form.hasOwnProperty('k')
               && this.invoiceData.payment.form.k !== ''
               && b && c
      }
    }
  },
  methods: {
    evalValidTaxRegime(o) {
      if(Array.isArray(this.session.business.taxpayer)) {
        const tp = this.session.business.taxpayer
        if(o.businessTaxpayer === null) {
          return tp[0]
        } else {
          /** Check if the current taxregime, yet exists */
          if(tp.find((i) => i.k === o.businessTaxpayer.k)) {
            return o.businessTaxpayer
          } else {
            return tp[0]
          }
        }
      } else {
        if(o.businessTaxpayer === null) {
          return this.session.business.taxpayer
        } else {
          /** Check if the current taxregime, yet exists */
          if(this.session.business.taxpayer.k === o.businessTaxpayer.k) {
            return o.businessTaxpayer
          } else {
            return this.session.business.taxpayer
          }
        }
      }
    },
    // ILL: 270722 convertir a mayuscula
    fmtCURP(value, event) {
        let v = value.toUpperCase()
        v = v.length > 18 ? v.substr(0,18) : v

        if(v.length == 18) {
          let r = /^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[MH]([ABCMTZ]S|[BCJMOT]C|[CNPST]L|[GNQ]T|[GQS]R|C[MH]|[MY]N|[DH]G|NE|VZ|DF|SP)[BCDFGHJ-NP-TV-Z]{3}[0-9A-Z][0-9]/

          if(!r.test(v)) {
            event.target.__vue__.state = false
          } else {
            event.target.__vue__.state = true
          }
        } else 
          event.target.__vue__.state = null

        return v
    },
    fmtUppercaseName(value) {
        let v = value.toUpperCase()        
        return v
    },
    getOptionKeyHelper (option) {
      return option.uuid
    },
    filterHelper (options, search) {
      return options.filter(option => {
        return ((option.id + ' - ' + option.name) || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      });
    },
    changeCustomer(e) {
      if(e) {
        if(['XAXX010101000','XEXX010101000'].find(a => a === this.invoiceData.customer.taxid)) {
          this.invoiceData.payment.method = this.paymentMethodList[0]
          this.cfdiSaved = false

          if(this.invoiceData.customer.name === 'PUBLICO EN GENERAL') {
            this.invoiceData.global.period = this.periodList[(new Date()).getMonth()]
            this.invoiceData.global.periodicity = this.periodicityList[0]
            this.invoiceData.global.year = this.years[0]
          } else {
            this.invoiceData.global.period = null
            this.invoiceData.global.periodicity = null 
            this.invoiceData.global.year = null 
            this.cfdiSaved = false
          }
        } else {
            this.invoiceData.global.period = null
            this.invoiceData.global.periodicity = null 
            this.invoiceData.global.year = null 
            this.cfdiSaved = false
          }
        

        this.invoiceData.customerTaxpayer = this.invoiceData.customer.taxertype
        this.invoiceData.customerInvoiceuse = this.invoiceData.customer.invoiceuse

        this.changeCustomerInvoiceUse(this.invoiceData.customerInvoiceuse)
      }
    },
    changeBusinessRegime(e) {
      this.currentConfig = []
      if(e) {
        this.invoiceData.businessTaxpayer = e 
        this.cfdiSaved = false    
      }
    },
    changeBusinessBranch(e) {
      if(e) {
        this.invoiceBranch = e
        this.cfdiSaved = false

        if(e.id === 'MAIN') {
          delete this.invoiceData.business.branch
        } else {
          this.invoiceData.business['branch'] = e
        }
      }
    },
    changeCustomerRegime(e) {
      if(e) {
        this.invoiceData.customerTaxpayer = e
        let a = this.invoiceuseList.filter(c => (c.pt.find(o => o === this.invoiceData.customer.type) && c.sr.find(u => u === this.invoiceData.customerTaxpayer.k)))
        this.invoiceData.customerInvoiceuse = a[0]
        this.cfdiSaved = false

        this.changeCustomerInvoiceUse(this.invoiceData.customerInvoiceuse)
      }
    },
    changeCustomerInvoiceUse(e) {
      this.currentConfig = {}

      if(e) {
        this.invoiceData.customerInvoiceuse = e
        let btp = this.invoiceData.businessTaxpayer
        this.cfdiSaved = false

        if(btp.k === '606' || btp.k === '601' || btp.k === '603' || btp.k === '626') {
          this.currentConfig['fg.leasing.realstate'] = true
        }

        if((btp.k === '601' || btp.k === '603' || btp.k === '626' || btp.k === '612' || btp.k === '621') && e.k === 'D10') {
          this.currentConfig['fg.school.private'] = true
        }
      }
    },
    changePaymentMethod(e) {
      this.cfdiSaved = false
      if(e) {
        if(this.invoiceData.payment.method.k === 'PPD') {
          this.paymentFormList.forEach(item => {
            if(item.k === '99') {
              this.invoiceData.payment.form = item
            }
          })
        } 
      }
    },
    calculate(idx) {
      this.cfdiSaved = false

      if(idx !== -1) {
        let item = this.invoiceData.items[idx]

      /** subtotal = ((price + (price * ieps_r / 100)) - discount ) * qty  */
        item.price    = parseFloat(item.price)
        item.price_tt = parseFloat(parseFloat(item.price).toFixed(2))
        if(item.taxes.ieps_t) {
          if(item.taxes.ieps_t.t === 'rate') {
            item.price_t['ieps'] = item.price * (parseFloat(item.taxes.ieps_t.r) / 100.0)
            item.price_tt += item.price_t['ieps']
          } else {
            item.price_t['ieps'] = parseFloat(item.taxes.ieps_t.q)
            item.price_tt += item.price_t['ieps']
          }
        }

        if(item.discount.value.indexOf('%') !== -1) {
          item.discount.amount = parseFloat((parseFloat(item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00)) * (parseFloat(item.discount.value.substr(0,item.discount.value.indexOf('%'))) / 100.00)).toFixed(2))
        } else {
          item.discount.amount = parseFloat(parseFloat(item.discount.value !== '' ? item.discount.value : 0.00).toFixed(2))
        }

        item.subtotal = parseFloat(parseFloat(item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00)).toFixed(this.invoiceData.payment.currency.de))
        item.subtotal_tt = parseFloat(parseFloat((item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00)) - item.discount.amount).toFixed(this.invoiceData.payment.currency.de))
        item.total = item.subtotal_tt

        item.taxes.total_t = 0.0
        item.taxes.total_r = 0.0
        
        if(item.taxes.iva_t) {
          if(item.taxes.iva_t.r === 'Exento'){
            item.taxes.iva_t.a = 0.0
            item.taxes.total_t += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
            item.total += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
          } else {
            item.taxes.iva_t.a = item.subtotal_tt * (parseFloat(item.taxes.iva_t.r) / 100)
            item.taxes.total_t += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
            item.total += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
          }
        }   
        
        if(item.taxes.ieps_t) {
          item.taxes.ieps_t.a = parseFloat(item.qty !== '' ? item.qty : '0') * item.price_t['ieps']
          /**item.total += item.taxes.ieps_t.a**/
        }   

        if(item.taxes.iva_r) {
          item.taxes.iva_r.a = item.subtotal_tt * (parseFloat(item.taxes.iva_r.r) / 100)
          item.taxes.total_r += parseFloat(parseFloat(item.taxes.iva_r.a).toFixed(this.invoiceData.payment.currency.de))
          item.total -= parseFloat(parseFloat(item.taxes.iva_r.a).toFixed(this.invoiceData.payment.currency.de))
        }

        if(item.taxes.isr_r) {
          item.taxes.isr_r.a = item.subtotal_tt * (parseFloat(item.taxes.isr_r.r) / 100)
          item.taxes.total_r += parseFloat(parseFloat(item.taxes.isr_r.a).toFixed(this.invoiceData.payment.currency.de))
          item.total -= parseFloat(parseFloat(item.taxes.isr_r.a).toFixed(this.invoiceData.payment.currency.de))
        }
      }

      this.invoiceData.taxes.total_raw_iva_t = 0;
      this.invoiceData.taxes.total_raw_ieps_t = 0;
      this.invoiceData.taxes.total_raw_iva_r = 0;
      this.invoiceData.taxes.total_raw_isr_r = 0;
      this.invoiceData.taxes.total_iva_t = 0;
      this.invoiceData.taxes.total_ieps_t = 0;
      this.invoiceData.taxes.total_iva_r = 0;
      this.invoiceData.taxes.total_isr_r = 0;
      this.invoiceData.summary.total_t = 0;
      this.invoiceData.summary.total_r = 0;
      this.invoiceData.summary.subtotal = 0;
      this.invoiceData.summary.discount = 0;
      this.invoiceData.summary.total = 0;
      
      for(let it of this.invoiceData.items) {
        if(it.taxes.iva_t) {
          this.invoiceData.taxes.total_raw_iva_t += it.taxes.iva_t.a
          this.invoiceData.taxes.total_iva_t += parseFloat(parseFloat(it.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
          this.invoiceData.summary.total_t += parseFloat(parseFloat(it.taxes.iva_t.a).toFixed(this.invoiceData.payment.currency.de))
        }

        if(it.taxes.ieps_t) {
          this.invoiceData.taxes.total_raw_ieps_t += it.taxes.ieps_t.a
          this.invoiceData.taxes.total_ieps_t += parseFloat(parseFloat(it.taxes.ieps_t.a).toFixed(this.invoiceData.payment.currency.de))
          this.invoiceData.summary.total_t += parseFloat(parseFloat(it.taxes.ieps_t.a).toFixed(this.invoiceData.payment.currency.de))
        }

        if(it.taxes.iva_r) {
          this.invoiceData.taxes.total_raw_iva_r += it.taxes.iva_r.a
          this.invoiceData.taxes.total_iva_r += parseFloat(parseFloat(it.taxes.iva_r.a).toFixed(this.invoiceData.payment.currency.de))
          this.invoiceData.summary.total_r += parseFloat(parseFloat(it.taxes.iva_r.a).toFixed(this.invoiceData.payment.currency.de))
        }

        if(it.taxes.isr_r) {
          this.invoiceData.taxes.total_raw_isr_r += it.taxes.isr_r.a
          this.invoiceData.taxes.total_isr_r += parseFloat(parseFloat(it.taxes.isr_r.a).toFixed(this.invoiceData.payment.currency.de))
          this.invoiceData.summary.total_r += parseFloat(parseFloat(it.taxes.isr_r.a).toFixed(this.invoiceData.payment.currency.de))
        }

        this.invoiceData.summary.subtotal += it.subtotal
        this.invoiceData.summary.discount += it.discount.amount
        this.invoiceData.summary.total += it.total
      }
    },
    addNewItemInItemForm() {
      /** Check if the product was previously added */
      for(let i = 0; i < this.invoiceData.items.length; ++i) {
        if(this.invoiceData.items[i].uuid === this.currentProduct.uuid && this.invoiceData.items[i].name === this.currentProduct.name) {
          this.invoiceData.items[i].qty += 1
          this.calculate(i)
          return
        }
      }

      const it = (o) => {
        let u = {
          uuid: o.uuid ? o.uuid : '',
          id: o.id ? o.id : '',
          satid: o.s ? o.s : '',
          satu: o.u ? o.u : '',
          satu_str: o.u_str ? o.u_str : '',
          sku: o.sku ? o.sku : '',
          qty: 1,
          type: o.type ? o.type : '',
          name: o.name ? o.name : '',
          config: o.config || '*',
          extra: o.extra || {},
          fixed: o.dq === 'fixed' ? true : false,
          price: o.dp ? parseFloat(o.dp) : 0.0,
          /** fer@arkeline.dev 24/03/2023 Add currency, for different currency calculations */
          currency: o.dpc || 'MXN',
          price_t : {

          },
          price_tt: 0.0,
          discount: {
            value: '',
            amount: 0.0,
          },
          subtotal: 0.0,
          subtotal_tt: 0.0,
          total: 0.0,
          taxes: {
            total_r: 0.0,
            total_t: 0.0
          },
          meta: {
            tax_object: '01'
          }
        }

        if(o.t.iva_ta === true) {
          if(o.t.iva_tr.k === 'Exento'){
            u.taxes['iva_t'] = {t:'exempt', r: 'Exento', a: 0.0 }
          } else {
            u.taxes['iva_t'] = {t:'rate', r: o.t.iva_tr.k, a: 0.0 }
          }
          u.meta.tax_object = '02'
        }

        if(o.t.iva_ra === true) {
          u.taxes['iva_r'] = {t:'rate', r: o.t.iva_rr.k, a: 0.0 }
          u.meta.tax_object = '02'
        }

        if(o.t.isr_ra === true) {
          u.taxes['isr_r'] = {t:'rate', r: o.t.isr_rr.k, a: 0.0 }
          u.meta.tax_object = '02'
        }

        if(o.t.ieps_ta === true) {
          if(o.t.ieps_tt === 'rate') {
            u.taxes['ieps_t'] = {t:'rate', r: o.t.ieps_tr.k, a: 0.0 }
            u.price_t['ieps'] = u.price * (parseFloat(o.t.ieps_tr.k) / 100.0)
            u.meta.tax_object = '02' 
          } else {
            u.taxes['ieps_t'] = {t:'quote', q: o.t.ieps_tq, a: parseFloat(o.t.ieps_tq) }
            u.price_t['ieps'] = parseFloat(o.t.ieps_tq)
            u.meta.tax_object = '02'
          }
        }

        if(o.t.ieps_ra === true) {
          if(o.t.ieps_rt === 'rate') {
            u.taxes['ieps_r'] = {t:'rate', r: o.t.ieps_rr.k, a: 0.0 }
            u.meta.tax_object = '02'
          } else {
            u.taxes['ieps_r'] = {t:'quote', q: o.t.ieps_rq, a: parseFloat(o.t.ieps_rq) }
            u.meta.tax_object = '02'
          }
        }

        return u
      }

      this.invoiceData.items.push(JSON.parse(JSON.stringify(it(this.currentProduct))))

      /** fer@arkeline.dev 24/03/2023 We set the default CFDi currency, for future product incorporations */
      this.currentCFDiCurrency          = this.currentProduct.dpc || 'MXN'
      this.invoiceData.payment.currency = this.currencyList.find(a => a.k === this.currentCFDiCurrency)

      this.calculate(this.invoiceData.items.length - 1)      
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.calculate(-1)

      /** fer@arkeline.dev 24/03/2023 If we detect that the product items line is size 0, we need to 
       *                              reset the currency calculations to 'MXN', and the currentCurrency selector to '' */

       if(this.invoiceData.items.length === 0) {
        this.currentCFDiCurrency = ''
        this.invoiceData.payment.currency = this.currencyList.find(a => a.k === 'MXN')
      }

      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formcfdiedit.scrollHeight)
      })
    },
    onSave() {
      this.onSaveBusy = true
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/updateCfdi', { uuid: this.invoiceUUID, cfdi: this.invoiceData })
        .then(() => {
          this.onSaveBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('CFDi Updated'),
              },
            })

            this.cfdiSaved = true
        })
    },
    onEmmit( feature ) {
      this.onEmmitBusy = true
      console.group('onEmmit')
      console.log('uuid', this.invoiceUUID)
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/emmitCfdi', { uuid: this.invoiceUUID, ...( feature && { feature })})
        .then((response) => {
          this.onEmmitBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('CFDi Sended with folio ') + response.data.folio,
              },
            })

          localStorage.setItem('tokenBundle', JSON.stringify(response.data.tokenBundle))
          this.$root.$emit('tokens-refresh',{})

          this.$router.replace({ name: 'fg-cfdi-list' })
            .then(() => {
              
            })

          console.log('response',response)
          console.groupEnd()


        })
        .catch((error) => {
          this.onEmmitBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                //text: error.error.message,
                text: error.error.message.startsWith('CFDI') ? error.error.message : this.$t(error.error.message),
              },
            })
        })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We add the session to handle emisor data */
    let session = JSON.parse(localStorage.getItem('session'))

    const contacts = ref([])
    store.dispatch('fg-cfdi/fetchContacts')
      .then(response => { contacts.value = response.data.contacts })

    const products = ref([])
    store.dispatch('fg-cfdi/fetchProducts')
      .then(response => { products.value = response.data.products })

    const years = ref([])
    let y0 = (new Date()).getFullYear()
    let y1 = y0 - 1
    years.value = [{k:''+y0,v:''+y0},{k:''+y1,v:''+y1}]

    let productSelected = {}

    const invoiceUUID = ref('')

    const invoiceData = ref({
      uuid: '',
      id: '',
      type: invoiceTypeList[0],
      business: null,
      businessTaxpayer: Array.isArray(session.business.taxpayer) ? session.business.taxpayer[0] : session.business.taxpayer,
      customer: null,
      customerTaxpayer: null,
      customerInvoiceuse: null,
      global: {
        period: null,
        periodicity: null,
        year: null,
      },
      items: [],
      summary: {
        subtotal: 0.0,
        discount: 0.0,
        total_t: 0.0,
        total_r: 0.0,
        total: 0.0
      },
      taxes: {
        total_raw_iva_t: 0.0,
        total_raw_ieps_t: 0.0,
        total_raw_iva_r: 0.0,
        total_raw_isr_r: 0.0,
        total_iva_t: 0.0,
        total_ieps_t: 0.0,
        total_iva_r: 0.0,
        total_isr_r: 0.0
      },
      addendum: {},
      observations: '',
      payment: {
        method: {},
        form: {}
      }
    })

    /** lsandoval: 19/12/2023 We create the placeholder for branch */
    const invoiceBranch = ref({
      id: 'MAIN',
      name: 'Principal',
      prefix: 'M',
      address: {
        street: '',
        suburb: { k: '', v: '' },
        town: { k: '', v: '' },
        state: { k: '', v: '' },
        country: { k: '', v: '' },
        zip: '',
        meta: {
          sat: {
            country: '',
            state: '',
            town: '',
            suburb: '',
            st: {
              m: 0,
              a: '',
              h: '',
              gm: -5
            },
            wt: {
              m: 0,
              a: '',
              h: '',
              gm: -6
            }
          } 
        }
      }
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    return {
      invoiceData,
      invoiceBranch,
      invoiceUUID,
      contacts,
      products,
      years,
      /** lsandoval: 09/02/2022 Session data */
      session,
      /** lsandoval: 10/02/2022 Product Selected data */
      productSelected,

      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.rw {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}

.menu_sticky {
  position: sticky;
  top: 145px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebe9f1;
}

.c1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 6.25%;
  max-width: 6.25%;
}

.c2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.c3 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 18.75%;
  max-width: 18.75%;
}

.c4 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 25.0%;
  max-width: 25.0%;
}

.c6 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.c8 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 50.0%;
  max-width: 50.0%;
}

.c10 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 62.5%;
  max-width:62.5%;
}

.c11 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 68.75%;
  max-width: 68.75%;
}

.c12 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 75.0%;
  max-width: 75.0%;
}

.c13 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 81.25%;
  max-width: 81.25%;
}

.c16 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 100.0%;
  max-width: 100.0%;
}


.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

/** Card body styles for cfdi uses as discount or something like that */
.cfdi-add-card-body {
  padding-top: 0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
}

</style>
