export default [
    {
        k: '01',
        v: 'Diario'
    },
    {
        k: '02',
        v: 'Semanal'
    },
    {
        k: '03',
        v: 'Quincenal'
    },
    {
        k: '04',
        v: 'Mensual'
    },
    {
        k: '05',
        v: 'Bimestral'
    }	
]
