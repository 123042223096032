export default [
    {
        k: '01',
        v: 'Efectivo',
        or: 1,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '02',
        v: 'Cheque nominativo',
        or: 5,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{11}|[0-9]{18}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        i: 'n',
        j: ['XEXX010101000']
    }, {
        k: '03',
        v: 'Transferencia electrónica de fondos',
        or: 2,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{10}|[0-9]{16}|[0-9]{18}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10}|[0-9]{18}',
        i: 'o',
        j: ['XEXX010101000']
    }, {
        k: '04',
        v: 'Tarjeta de crédito',
        or: 4,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{16}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        i: 'n',
        j: ['XEXX010101000']
    }, {
        k: '05',
        v: 'Monedero electrónico',
        or: 7,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        i: 'n',
        j: ['n']
    }, {
        k: '06',
        v: 'Dinero electrónico',
        or: 8,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{10}',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '08',
        v: 'Vales de despensa',
        or: 9,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '12',
        v: 'Dación en pago',
        or: 10,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '13',
        v: 'Pago por subrogación',
        or: 11,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '14',
        v: 'Pago por consignación',
        or: 12,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '15',
        v: 'Condonación',
        or: 13,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '17',
        v: 'Compensación',
        or: 14,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '23',
        v: 'Novación',
        or: 15,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '24',
        v: 'Confusión',
        or: 16,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '25',
        v: 'Remisión de deuda',
        or: 17,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '26',
        v: 'Prescripción o caducidad',
        or: 18,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '27',
        v: 'A satisfacción del acreedor',
        or: 19,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '28',
        v: 'Tarjeta de débito',
        or: 3,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{16}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        i: 'n',
        j: ['XEXX010101000']
    }, {
        k: '29',
        v: 'Tarjeta de servicios',
        or: 20,
        a: 's',
        b: 'o',
        c: 'o',
        d: 'o',
        e: '[0-9]{15,16}',
        f: 'o',
        g: 'o',
        h: '[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}',
        i: 'n',
        j: ['XEXX010101000']
    }, {
        k: '30',
        v: 'Aplicación de anticipos',
        or: 21,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '31',
        v: 'Intermediario pagos',
        or: 22,
        a: 'n',
        b: 'o',
        c: 'n',
        d: 'n',
        e: 'n',
        f: 'n',
        g: 'n',
        h: 'n',
        i: 'n',
        j: ['n']
    }, {
        k: '99',
        v: 'Por definir',
        or: 6,
        a: 'o',
        b: 'o',
        c: 'o',
        d: 'o',
        e: 'o',
        f: 'o',
        g: 'o',
        h: 'o',
        i: 'o',
        j: ['o']
    }    
    ]