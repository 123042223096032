export default [
    {
          k: 'MXN',
          v: 'Peso Mexicano',
          or: 1,
          de: 2
        },
        {
          k: 'USD',
          v: 'Dólar americano',
          or: 2,
          de: 2
        }
        ]
        