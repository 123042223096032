export default [
    {
        k: '601',
        v: 'General de Ley Personas Morales',
        pt: ['pm']
    },
    {
        k: '603',
        v: 'Personas Morales con Fines no Lucrativos',
        pt: ['pm']
    },
    {
        k: '605',
        v: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
        pt: ['pf']
    },
    {
        k: '606',
        v: 'Arrendamiento',
        pt: ['pf']
    },
    {
        k: '607',
        v: 'Régimen de Enajenación o Adquisición de Bienes',
        pt: ['pf']
    },
    {
        k: '608',
        v: 'Demás ingresos',
        pt: ['pf']
    },
    {
        k: '610',
        v: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
        pt: ['pm', 'pf']
    },
    {
        k: '611',
        v: 'Ingresos por Dividendos (socios y accionistas)',
        pt: ['pf']
    },
    {
        k: '612',
        v: 'Personas Físicas con Actividades Empresariales y Profesionales',
        pt: ['pf']
    },
    {
        k: '614',
        v: 'Ingresos por intereses',
        pt: ['pf']
    },
    {
        k: '615',
        v: 'Régimen de los ingresos por obtención de premios',
        pt: ['pf']
    },
    {
        k: '616',
        v: 'Sin obligaciones fiscales',
        pt: ['pf']
    },
    {
        k: '620',
        v: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
        pt: ['pm']
    },
    {
        k: '621',
        v: 'Incorporación Fiscal',
        pt: ['pf']
    },
    {
        k: '622',
        v: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
        pt: ['pm']
    },
    {
        k: '623',
        v: 'Opcional para Grupos de Sociedades',
        pt: ['pm']
    },
    {
        k: '624',
        v: 'Coordinados',
        pt: ['pm']
    },
    {
        k: '625',
        v: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
        pt: ['pf']
    },
    {
        k: '626',
        v: 'Régimen Simplificado de Confianza',
        pt: ['pm', 'pf']
    }
]